import dynamic from 'next/dynamic';
import GET_RECOMMEND_CAMPAIGNS from './queries/GET_RECOMMEND_CAMPAIGNS.graphql';
import GET_TRENDING_CAMPAIGNS from './queries/GET_TRENDING_CAMPAIGNS.graphql';
import GET_NEARME_CAMPAIGNS from './queries/GET_NEARME_CAMPAIGNS.graphql';
import GET_FAVOURITE_CAMPAIGNS from './queries/GET_FAVOURITE_CAMPAIGNS.graphql';
import GET_MY_TOP_CAMPAIGNS from './queries/GET_MY_TOP_CAMPAIGNS.graphql';
import GET_CATEGORIES_TAG from './queries/GET_CATEGORIES_TAG.graphql';
import GET_CAMPAIGN_FOR_TAG from './queries/GET_CAMPAIGN_FOR_TAG.graphql';
import { POSITION } from '../../ui/carousel';

interface IImports {
  [key:string]: any
}

const defaultComp = {
  component: ({ children }:any) => (<>{children}</>),
  props: {
    required: [],
  },
};

/**
 * List of components that can be used in dynamic section
 */
const Components:IImports = {
  'withData/cards/charity/small': {
    component: dynamic(() => import('../cards/charity/small')),
    props: {
      cardSize: 310,
      required: ['key', 'id', 'logo', 'name', 'description'],
    },
  },
  'withData/cards/categories': {
    component: dynamic(() => import('../../ui/cards/categories')),
    props: {
      cardSize: 125,
      startFrom: POSITION.LEFT,
      required: ['key', 'id', 'imageURL', 'cardName'],
    },
  },
  'ui/carousel': {
    component: dynamic(() => import('../../ui/carousel')),
    props: {
      required: ['cardSize', 'startFrom'],
    },
  },
  'ui/verticalScroll': {
    component: dynamic(() => import('../../ui/verticalScroll')),
    props: {
      required: [],
    },
  },
  'withData/cards/location': {
    component: dynamic(() => import('../cards/location')),
    props: {
      required: [],
    },
  },
  'withData/cards/postcode': {
    component: dynamic(() => import('../cards/postcode')),
    props: {
      required: [],
    },
  },
  default: defaultComp,
};

export default Components;

export const Queries:IImports = {
  getRecommendedCampaigns: GET_RECOMMEND_CAMPAIGNS,
  getTrendingCampaigns: GET_TRENDING_CAMPAIGNS,
  getNearUserCampaigns: GET_NEARME_CAMPAIGNS,
  getFavouriteCampaigns: GET_FAVOURITE_CAMPAIGNS,
  getMyTopCampaigns: GET_MY_TOP_CAMPAIGNS,
  getTags: GET_CATEGORIES_TAG,
  getCampaignsForTag: GET_CAMPAIGN_FOR_TAG,
};
