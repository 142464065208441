import { useQuery } from "@apollo/react-hooks";
import { useContext } from "react";

import { useRouter } from "next/router";
import Section from "../../pages/browse/section";
import Components, { Queries } from "./imports";
import { withApollo } from "../../../helpers/apollo";
import { Context } from "../../../helpers/context";
import RenderWithData from "./renderer";

import GET_USER from "./GET_USER.graphql";
import { ROUTE } from "../../../constants";
import centreUPF from "../../../helpers/centreUPF";
import React from "react";
// import { getUPFURI } from '../../../helpers/getUpf';

const getParentChild = (render: string) => {
  const chunks: string[] = render.split("-") || [];
  const parent = Components[chunks[0] || "default"];
  const child = Components[chunks[1] || "default"];
  return {
    parent,
    child,
  };
};

const Browse = ({ schema = [], tagId, isVertical = false }: any) => {
  const route = useRouter();

  const { context: { auth = {} } = {} } = useContext(Context);

  const { data } = useQuery(GET_USER, {
    fetchPolicy: "cache-and-network",
    skip: !auth.username,
  });
  let { user = {} } = data || {};

  const onSeeMoreSection = (section: string) => {
    const upfRI = centreUPF(auth);
    // console.log('upfRI', upfRI);
    // console.log('section', section);
    // const upfRI = (auth && auth.username) ? getUPFURI(auth.username) : '';
    const sectionLink = `${ROUTE.BROWSE}/[browseId]${upfRI}`;
    route.push(sectionLink, `${ROUTE.BROWSE}/${section}${upfRI}`);
  };

  const onSelectCategory = async (value: any) => {
    const { encodeUrl } = value;
    const upfRI = centreUPF(auth);
    // const upfRI = (auth && auth.username) ? getUPFURI(auth.username) : '';
    const tempUrl = `${ROUTE.CAMPAIGN_TAGS}/[id]${upfRI}`;
    route.push(tempUrl, `${ROUTE.CAMPAIGN_TAGS}/${encodeUrl}${upfRI}`);
  };

  let sections = schema;
  if (!auth.isValidUser) {
    sections = schema.filter(({ guest }: any) => guest);
  }

  if (tagId) {
    user = {
      tagId: tagId,
    };
  }
  const pagination = {
    offset: 0,
    limit: 10,
  };
  return sections.map((section: any,index:any) => {
    const {
      render = {},
      required,
      rows,
      showMoreDecorator = section.showMore,
      maxColumns,
      maxRows,
      dataTransformCallback = "",
    } = section;
    const query = Queries[section.query];
    return (
      <Section
        key={section.id}
        heading={section.title}
        title={section.description}
      >
        {query ? (
          <RenderWithData
            key={`${index + 1}`}
            valid={getParentChild(render.valid)}
            invalid={getParentChild(render.invalid)}
            onSelectCategory={onSelectCategory}
            required={required}
            query={query}
            queryParams={pagination}
            rows={rows}
            maxRows={maxRows}
            sectionCount={sections.length}
            user={user}
            section={section.id}
            onClick={onSeeMoreSection}
            showMore={showMoreDecorator}
            maxColumns={maxColumns}
            callback={dataTransformCallback}
            isVertical={isVertical}
          />
        ) : (
          ""
        )}
      </Section>
    );
  });
};

export default withApollo()(Browse);
