import * as React from 'react';

import {
  Header,
  Paragraph,
  HeadGroup,
  CustomWrapper,
  ContentWrap,
} from '../browse.style';

interface IProps {
  heading: string
  title?: string
  children?: React.ReactNode
  showArrow?: boolean
  onClick?: ()=>void
}

export const BrowseSection: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    heading,
    title,
    children,
  } = props;
  return (
    <CustomWrapper>
      <HeadGroup collapse={!title}>
        {heading ? (
          <Header>
            {heading}
          </Header>
        ) : null}
        {title ? <Paragraph>{title}</Paragraph> : null}
      </HeadGroup>
      <ContentWrap>{children}</ContentWrap>
    </CustomWrapper>
  );
};

export default BrowseSection;
