import styled, { css } from 'styled-components';
import { GlobalWrapper } from '../../global.style';


export const CustomWrapper = styled(GlobalWrapper)`
  padding: 0;
`;

export const Header = styled.h2`
 ${({ theme: { primary, font }, isContent }: any) => css`
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${font.size.text.md};
  ${isContent && `
    margin-top: 0;
  `}
  color: ${primary};
 `}
`;

export const Paragraph = styled.p`
 ${({ theme: { shades, font } }: any) => css`
  font-weight: ${font.weight.normal};
  font-size: ${font.size.text.sm};
  margin-top: 0;
  color: ${shades[3]};
 `}
`;

export const HeadGroup = styled.div`
  ${({ collapse }: any) => css`
    display: block;
    ${collapse
    ? 'padding: 20px 30px 0;'
    : `padding: 20px 30px;
    `}
  `}
`;

const reuseArrowStyle = (shades: any) => css`
  position: absolute;
  display: block;
  content: '';
  width: 10px;
  height: 2px;
  background-color: ${shades[3]};
  border-radius: 5px;
`;

export const RightArrowButton = styled.div`
  ${({ theme: { shades }, position }: any) => css`
    display: block;
    position: absolute;

    ${position ? `
      top: ${position.top}px;
      right: ${position.right}px;
    ` : `
      top: -5px;
      right: -10px;
    `}
    width: 28px;
    height: 28px;
    background-color: transparent;

    ::before {
      ${reuseArrowStyle(shades)};
      top: 40%;
      left: 52%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    ::after {
      ${reuseArrowStyle(shades)};
      top: 60%;
      left: 52%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    :active {
      ::before {
        background-color: ${shades[4]};
      }

      ::after {
        background-color: ${shades[4]};
      }
    }
  `}
`;

export const DownArrowButton = styled(RightArrowButton)`
  ${({ theme: { shades } }: any) => css`
    transform: rotate(90deg);
    ::before {
      ${reuseArrowStyle(shades)};
      top: 40%;
      left: 52%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    ::after {
      ${reuseArrowStyle(shades)};
      top: 60%;
      left: 52%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  `}
`;

export const ContentWrap = styled.div`
  padding-bottom: 20px;
  &>div:not(:last-child){
    margin-bottom: 10px;
  }
`;

export const List = styled.div`
margin: 0 5px 20px;
`;


export default Header;
