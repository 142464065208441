import React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import getConfig from "next/config";
import { withApollo } from "../../../helpers/apollo";
import Loading from "../../ui/loading";
import { RightArrowButton } from "../../pages/browse/browse.style";
import divideArray from "../../../helpers/arrayDivide";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";

export const NoMoreData = styled.span`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`;
const MIN_SECTION = 5;
const MAX_ROW_COUNT = 2;
const MAX_COL_IF_ONE_ROW = 7;
const { publicRuntimeConfig } = getConfig();

const { LOCATION_KM_RADIUS } = publicRuntimeConfig;

const bindProps = (keys: string[], props: any) => {
  let result: any = {};
  keys.map((i) => {
    result = {
      ...result,
      [i]: props[i],
    };
    return i;
  });
  return result;
};

interface IRenderProps {
  query: any;
  valid: any;
  invalid: any;
  required?: string[];
  rows?: number;
  sectionCount?: number;
  user?: any;
  showMore?: boolean;
  section?: string;
  onClick?: (section: any) => void;
  onSelectCategory?: (value: any) => void;
  maxColumns?: number;
  maxRows?: number;
  callback?: string;
  isVertical?: boolean;
  queryParams?: { skip: number; limit: number };
}

const Render = ({
  query,
  queryParams,
  valid,
  invalid,
  required = [],
  rows = 1,
  sectionCount = 0,
  user = {},
  showMore = true,
  section = "",
  onClick = () => {},
  onSelectCategory = () => {},
  maxColumns = 0,
  maxRows = 0,
  isVertical = false,
}: // callback = '',
IRenderProps) => {
  const initState = {
    kmRadius: LOCATION_KM_RADIUS,
    ...user,
    offset: queryParams?.skip,
    limit: queryParams?.limit ?? 10,
    scrolled: false,
    items: [],
    hasMore: true,
  };
  if (maxColumns) {
    initState.limit = maxRows * maxColumns;
  }
  const [state, setState] = useState(initState);
  const [isItems,setIsItems]=useState([])as any[];
  const updateState = (update: any) => {
    setState((prevState: any) => ({ ...prevState, ...update }));
  };

  const { longitude = 0 } = state;

  const isSkipped = longitude === 0 && !!query.getNearUserCampaigns;
  const { kmRadius, limit, skip, tagId } = state;
  const { data, loading, error } = useQuery(query, {
    variables: {
      kmRadius,
      limit,
      skip,
      tagId,
    },
    fetchPolicy: "cache-and-network",
    skip: isSkipped,
  });
  let { child } = valid;
  let { parent } = valid;
  let rowCount = rows;

  if (error || isSkipped) {
    child = invalid.child;
    parent = invalid.parent;
    rowCount = 1;
  }
  let Parent = parent.component;
  let Child = child.component;
  let parentProps = bindProps(parent.props.required, child.props);
  const UpdateData=(itemsList:any[])=>{
    if (!state?.scrolled && itemsList) {
      setIsItems([...state.items, ...itemsList])
      setState({
        ...state,
        items: [...state.items, ...itemsList],
        scrolled: true,
        skip:state.items.length,
        hasMore: itemsList.length < 10 ? false : true,
      });
    }
    if(itemsList === null){
      setState({
        ...state,
        hasMore:false

      })
    }

  }
  if (sectionCount <= MIN_SECTION && !error && showMore) {
    rowCount = MAX_ROW_COUNT;
  }
  const fetchMoreData = () => {
    if (!state.scrolled) return;
    setState({
      ...state,
      scrolled: false,
      skip: state.items.length,
    });
  };
  const itemChunks = divideArray(data?.campaigns?.items || isItems, rowCount);
  useEffect(()=>{
    if (loading && !data) {
      return;
    }
    let { campaigns: { items = [] } = {} } = data || {};
    UpdateData(items)
  },[loading,data])
  return (
    <>
      {showMore && !error && (
        <RightArrowButton
          //@ts-ignore
          position={{ top: 20, right: 15 }}
          onClick={() => onClick(section)}
        />
      )}
      {[...Array(rowCount).keys()].map((key: any, point: any) => {
        const rowItems = itemChunks[key] || [];
        //@ts-ignore
        if (!rowItems.length) return null;
        if (rowCount < MAX_ROW_COUNT && !isVertical) {
          //@ts-ignore
          rowItems.length = MAX_COL_IF_ONE_ROW;
        }
        return window.location.toString().includes("campaigntag") ? (
          <>
            <InfiniteScroll
              dataLength={state?.items?.length}
              next={fetchMoreData}
              hasMore={state?.hasMore}
              height={725}
              loader={<Loading />}
              endMessage={<NoMoreData>No more items</NoMoreData>}
            >
              <Parent key={`parent_${key}_${point}`} {...parentProps}>
                {isItems?.map((campaign: any, index: any) => {
                  const childProps = bindProps(child.props.required, campaign);
                  const sectionItem = `${section}-${index}-${key}`;
                  return (
                    <Child
                      key={sectionItem}
                      {...childProps}
                      sectionItem={sectionItem}
                      onSelectCategory={onSelectCategory}
                      getData={(res: any = {}) => {
                        if ((res || {}).error) return;
                        const queryVars = bindProps(required, res);
                        updateState({ ...queryVars });
                      }}
                    />
                  );
                })}
              </Parent>
            </InfiniteScroll>
          </>
        ) : (
          <Parent key={`parent_${key}_${point}`} {...parentProps}>
            {rowItems?.map((campaign: any, index: any) => {
              const childProps = bindProps(child.props.required, campaign);
              const sectionItem = `${section}-${index}-${key}`;
              return (
                <Child
                  key={index}
                  {...childProps}
                  sectionItem={sectionItem}
                  onSelectCategory={onSelectCategory}
                  getData={(res: any = {}) => {
                    if ((res || {}).error) return;
                    const queryVars = bindProps(required, res);
                    updateState({ ...queryVars });
                  }}
                />
              );
            })}
          </Parent>
        );
      })}
    </>
  );
};

export default withApollo()(Render);
